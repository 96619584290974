<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-col sm="8" md="8" lg="8">
          <v-layout align-center fill-height>
            {{
              $t("Wens je") +
              " " +
              validation.Customer.FirstName +
              " " +
              validation.Customer.LastName +
              " " +
              $t("aan te passen van huidig adres naar nieuw adres?")
            }}
          </v-layout>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters class="mb-4">
        <v-col>
          <p class="mb-0">
            {{
              $t("Name") +
              ": " +
              validation.Customer.FirstName +
              " " +
              validation.Customer.LastName
            }}
          </p>
          <p class="mb-0">
            {{
              $t("BirthDate") +
              ": " +
              helpers.changeTimeToReadebleDMY(validation.Customer.BirthDate)
            }}
          </p>
          <p class="mb-0">
            {{ $t("Email") + ": " + validation.Customer.Email }}
          </p>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          <p class="text-subtitle-1 mb-1 font-weight-bold">
            {{ $t("Current adress") }}
          </p>
          <p class="mb-0">
            {{ $t("Country") + ": " + validation.FromAddress.Country }}
          </p>
          <p class="mb-0">
            {{
              $t("City") +
              ": " +
              validation.FromAddress.City +
              " " +
              validation.FromAddress.Zip
            }}
          </p>
          <p class="mb-0">
            {{
              $t("Street and number") +
              ": " +
              validation.FromAddress.Street +
              " "
            }}
          </p>
          <v-row no-gutters v-if="validation.CustomersOnFromAddress.length">
            <v-col cols="12">
              <p class="text-subtitle-1 mb-1 mt-4 font-weight-bold">
                {{ $t("People at current address") }}
              </p>
            </v-col>
            <v-col
              v-for="(address, index) in validation.CustomersOnFromAddress"
              :key="index"
              class="mb-1"
              cols="8"
            >
              <p class="mb-0" v-if="address.Type">
                {{ $t("Type") + ": " + address.Type.Name }}
              </p>
              <p class="mb-0">
                {{
                  $t("Name") + ": " + address.FirstName + " " + address.LastName
                }}
              </p>
              <p class="mb-0">
                {{
                  $t("Date of birth") +
                  ": " +
                  helpers.changeTimeToReadebleDMY(address.BirthDate)
                }}
              </p>
              <p class="mb-0">
                {{ $t("Email") + ": " + address.Email }}
              </p>
              <v-divider
                v-if="
                  index + 1 != validation.CustomersOnFromAddress.length &&
                  validation.CustomersOnFromAddress.length > 1
                "
                class="my-1"
              ></v-divider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2">
          <v-layout align-center fill-height>
            <v-icon> mdi-arrow-right </v-icon>
          </v-layout>
        </v-col>
        <v-col cols="4">
          <p class="text-subtitle-1 mb-1 font-weight-bold">
            {{ $t("New address") }}
          </p>

          <p class="mb-0">
            {{ $t("Country") + ": " + validation.ToAddress.Country }}
          </p>
          <p class="mb-0">
            {{
              $t("City") +
              ": " +
              validation.ToAddress.City +
              " " +
              validation.ToAddress.Zip
            }}
          </p>
          <p class="mb-0">
            {{
              $t("Street and number") + ": " + validation.ToAddress.Street + " "
            }}
          </p>
          <v-row no-gutters v-if="validation.CustomersOnToAddress.length">
            <v-col cols="12">
              <p class="text-subtitle-1 mb-1 mt-4 font-weight-bold">
                {{ $t("People at new address") }}
              </p>
            </v-col>
            <v-col
              v-for="(address, index) in validation.CustomersOnToAddress"
              :key="index"
            >
              <p class="mb-0" v-if="address.Type">
                {{ $t("Type") + ": " + address.Type.Name }}
              </p>
              <p class="mb-0">
                {{
                  $t("Name") + ": " + address.FirstName + " " + address.LastName
                }}
              </p>
              <p class="mb-0">
                {{
                  $t("Date of birth") +
                  ": " +
                  helpers.changeTimeToReadebleDMY(address.BirthDate)
                }}
              </p>
              <p class="mb-0">
                {{ $t("Email") + ": " + address.Email }}
              </p>
              <v-divider
                v-if="
                  index + 1 != validation.CustomersOnToAddress.length &&
                  validation.CustomersOnToAddress.length > 1
                "
                class="my-1"
              ></v-divider>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <cardValdiationButtons
        :validation="validation"
        :needsCancelButton="true"
        :ignoreButtonName="$t('Ignore change')"
        :successButtonName="$t('change adress')"
        @deleteOutArray="$emit('deleteOutArray', $event)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import helpers from "../../helpers";
export default {
  props: ["validation"],
  data() {
    return {
      helpers: helpers,
    };
  },
};
</script>

<style>
</style>